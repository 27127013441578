import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

// Configuração do Firebase
const firebaseConfig1 = {
  apiKey: "AIzaSyCO3dicnFgkU9fWbMIs_XIVOv-jMpVeEjE",
  authDomain: "agenda-barao.firebaseapp.com",
  projectId: "agenda-barao",
  storageBucket: "agenda-barao.firebasestorage.app",
  messagingSenderId: "219773549070",
  appId: "1:219773549070:web:0788958515296f9c329a5f",
  measurementId: "G-3653N9M2VJ"
};

// Inicializa o aplicativo Firebase
const app = firebase.initializeApp(firebaseConfig1, 'app1');

// Inicializa o Firestore e Auth
const db = app.firestore();
const auth = app.auth();

// Exporta o Firestore, Auth e App
export { db, auth, app };
